import React from 'react';
import './HeroSection.module.css';
import HeroButton from '../HeroButton/HeroButton';

const HeroSection = ({
  title,
  subtitle,
  additionalMessage = "", // Novo prop para propósito
  showButtons,
  buttons = [],
  logoSrc
}) => {
  return (
    <div className="hero-section">
      {logoSrc && <img src={logoSrc} alt="Logo" className="logo" />}
      <h1 className="headline">{title}</h1>
      <p className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
      {additionalMessage && (
        <p className="additional-message" dangerouslySetInnerHTML={{ __html: additionalMessage }} /> // Exibe a mensagem adicional
      )}
      {showButtons && (
        <div className="buttons">
          {buttons.map((button, index) => (
            <HeroButton key={index} {...button} />
          ))}
        </div>
      )}
    </div>
  );
};

export default HeroSection;
