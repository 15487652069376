import React, { useEffect } from 'react';
import './PlexusBackground.css';

const PlexusBackground = () => {
  useEffect(() => {
    const canvas = document.getElementById('plexusCanvas');
    if (!canvas) return;

    const ctx = canvas.getContext('2d');

    const resizeCanvas = () => {
      const parent = canvas.parentElement;
      canvas.width = parent.offsetWidth;
      canvas.height = parent.offsetHeight;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    const generatePoints = (count) => {
      const points = [];
      for (let i = 0; i < count; i++) {
        points.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          vx: (Math.random() - 0.5) * 0.5,
          vy: (Math.random() - 0.5) * 0.5,
        });
      }
      return points;
    };

    const connectPoints = (points) => {
      for (let i = 0; i < points.length; i++) {
        for (let j = i + 1; j < points.length; j++) {
          const dist = Math.hypot(points[i].x - points[j].x, points[i].y - points[j].y);
          if (dist < 100) {
            ctx.strokeStyle = `rgba(51, 51, 51, ${1 - dist / 100})`;
            ctx.beginPath();
            ctx.moveTo(points[i].x, points[i].y);
            ctx.lineTo(points[j].x, points[j].y);
            ctx.stroke();
          }
        }
      }
    };

    const draw = (points) => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      points.forEach((point) => {
        point.x += point.vx;
        point.y += point.vy;

        if (point.x < 0 || point.x > canvas.width) point.vx *= -1;
        if (point.y < 0 || point.y > canvas.height) point.vy *= -1;

        ctx.fillStyle = 'rgba(51, 51, 255, 0.8)';
        ctx.beginPath();
        ctx.arc(point.x, point.y, 2, 0, Math.PI * 2);
        ctx.fill();
      });

      connectPoints(points);
      requestAnimationFrame(() => draw(points));
    };

    // Adapte a quantidade de pontos com base na largura da tela
    const pointsCount = window.innerWidth < 768 ? 50 : 100; // 50 pontos para telas menores
    const points = generatePoints(pointsCount);
    draw(points);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return <canvas id="plexusCanvas" className="plexus-background"></canvas>;
};

export default PlexusBackground;
