import React, { useEffect } from 'react';
import { useAppContext } from './context/AppContext';
import HeroSection from './components/HeroSection/HeroSection';
import Container from './components/Container/Container';
import './App.css';
import Navbar from './components/Navbar/Navbar';

function App() {
  const links = [
    /*{ text: 'Quem Somos', href: '#quem-somos' },
    { text: 'Serviços', href: '#servicos' },
    { text: 'Depoimentos/Projetos', href: '#projetos' },
    { text: 'Insights', href: '#insights' },
    { text: 'Contato', href: '#contato' },*/
  ];
  const { currentPage, setCurrentPage } = useAppContext();

  useEffect(() => {
    document.body.className = currentPage === 'login' ? 'login' : 'home';
  }, [currentPage]);

  return (
    <div className={`App ${currentPage === 'home' ? 'green' : 'beige'}`}>
      {currentPage === 'home' ? (
        <Container>
          <HeroSection
            title="APRIMORA"
            subtitle="<span class='highlight'>O FUTURO</span> começa com as <span class='highlight'>CONEXÕES CERTAS</span>"
            additionalMessage="A Aprimora é uma agência de inteligência com uma variedade de serviços. <br/>Conectamos inteligência humana e artificial para transformar desafios em oportunidades. <br/><br/>e-mail: contato@aprimora.ai"
            showButtons={false}
            buttons={[{
              text: 'Quem Somos',
              className: 'primary-button',
              onClick: () => setCurrentPage('quem-somos'),
            }, {
              text: 'Serviços',
              className: 'secondary-button',
              onClick: () => setCurrentPage('servicos'),
            }]}
            logoSrc="/assets/logo.png"
          />

          <Navbar links={links} />
        </Container>
      ) : (
        <div className="login">
          <h2 className="headline">Login</h2>
          <button onClick={() => setCurrentPage('home')} className="primary-button">Voltar</button>
        </div>
      )}
    </div>
  );
}

export default App;