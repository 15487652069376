import React from 'react';
import './Container.module.css';
import PlexusBackground from '../PlexusBackground/PlexusBackground';

const Container = ({ children, gridConfig = null, showPlexus = true }) => {
  return (
    <div className={gridConfig ? 'grid-container' : 'container'}>
      {showPlexus && <PlexusBackground />}
      {gridConfig
        ? React.Children.map(children, (child, index) => {
            const config = gridConfig[index] || {};
            const style = {
              gridColumn: config.startColumn ? `${config.startColumn} / span ${config.colSpan}` : 'auto',
              gridRow: config.startRow ? `${config.startRow} / span ${config.rowSpan}` : 'auto',
            };
            return (
              <div className="grid-item" style={style}>
                {child}
              </div>
            );
          })
        : children}
    </div>
  );
};

export default Container;
